import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTwitter, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import styles from './Footer.module.scss';
import { AppRoutes } from 'routes/routes';
import OptimizedImage from '../common/OptimizedImage';

const Footer: React.FC = () => {
  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <Row className="gy-4">
          <Col lg={5} md={12} className={styles.footerInfo}>
            <a href={AppRoutes.home}>
              <div className={styles.logoContainer}>
                <OptimizedImage
                  src="/images/blogo.png"
                  alt="Bhandara Logo"
                  width={150}
                  height={50}
                  loading="lazy"
                  className={styles.blogo}
                />
              </div>
            </a>
            <h5>
              <b>India&apos;s 1st spiritual food app.</b>
            </h5>
            <div className={styles.socialLinks}>
              {/* <a
                href="https://twitter.com/bhandaara?t=aUBMr7O2TT_aiqXPkdZsWQ&s=08"
                className={styles.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a> */}
              <a
                href="https://www.facebook.com/bhandaraapp?mibextid=ZbWKwL"
                className={styles.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/bhandara.app/?igsh=aDRhejJ1OHdxM2t0"
                className={styles.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/bhandara/"
                className={styles.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
            <div className={styles.downloadApp}>
              <div className={styles.storeButtons}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.bhandara.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OptimizedImage
                    src="/images/playstore.png"
                    alt="Get it on Google Play"
                    width={135}
                    height={135}
                    loading="lazy"
                    className={styles.storeButton}
                  />
                </a>

                <a
                  href="https://apps.apple.com/in/app/bhandara/id6739462189"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OptimizedImage
                    src="/images/Appstore.webp"
                    alt="Get it on App Store"
                    width={135}
                    // height={135}
                    loading="lazy"
                    className={styles.storeButton}
                  />
                </a>
              </div>
            </div>
          </Col>

          <Col lg={2} xs={6} className={styles.footerLinks}>
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href={AppRoutes.blog}>Blogs</a>
              </li>
              <li>
                <a href={AppRoutes.about}>About us</a>
              </li>
              <li>
                <a href={AppRoutes.contact}>Contact us</a>
              </li>
              <li>
                <a href={AppRoutes.privacypolicy}>Privacy policy</a>
              </li>
            </ul>
          </Col>

          <Col lg={2} xs={6} className={styles.footerLinks}>
            <h4>Our Products</h4>
            <ul>
              <li>
                <a href={AppRoutes.corporate}>Corporate Bookings</a>
              </li>
              <li>
                <a href={AppRoutes.nri}>NRI Portal</a>
              </li>
              <li>
                <a
                  href={AppRoutes.bhandarakarwao}
                >
                  Bandara karwao
                </a>
              </li>
            </ul>
          </Col>

          <Col lg={3} md={12} className={styles.footerContact}>
            <h4>Reach Us</h4>
            <p>
              <span className={styles.companyInfo}>
                AKHILA APPS PVT LTD
                <br />
                CIN: U56291MP2024PTC072437
                <br />
              </span>
              <strong>
                <FaMapMarkerAlt />
              </strong>{" "}
              Registered Head Office:
              <br />
              Jadon Market,
              <br />
              Patali Hanuman Tansen Road, Hazira, Gwalior (MP)
              <br />
              474003
              <br />
              <br />
              <strong>
                <FaMapMarkerAlt />
              </strong>{" "}
              Corporate Office:
              <br />
              Awfis
              <br />
              7th Floor,
              <br />
              Gate No. 03 & Gate No. 04,
              <br />
              Ambience Island, NH 48, Gurugram,
              <br />
              122002
              <br />
              <br />
              <strong>
                <FaPhone />
              </strong>{" "}
              +91 8595316628
              <br />
              <strong>
                <FaEnvelope />
              </strong>{" "}
              contact@bhandara.app
              <br />
            </p>
          </Col>
        </Row>
      </Container>

      <Container className={styles.copyrightContainer}>
        <div className={styles.copyright}>
          <h4>
            &copy; Copyright{" "}
            <strong>
              <span>2024 Bhandara App</span>
            </strong>
            . All Rights Reserved
          </h4>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
